import Blog1 from './blog1.md';
// import Blog2 from './blog2.md';
// import Blog3 from './blog3.md';

const blogs = [
  {id: 1, title: 'Hello World', excerpt: 'Origins of Kelly Enterprises', content: Blog1},
  // {id: 2, title: 'Blog Title 2', excerpt: 'excerpt from Blog 2', content: Blog2},
  // {id: 3, title: 'Blog Title 3', excerpt: 'excerpt from Blog 3', content: Blog3},
];

export default blogs;