const products = [
    {
        id: 'hoodie',
        name: 'Branded Hoodie',
        description: 'Stay warm and stylish with our branded hoodie.',
        price: 50.00,
        images: ['hoodie/hoodie_1.png', 'hoodie/hoodie_2.png', 'hoodie/hoodie_3.png', 'hoodie/hoodie_4.png'],
        currentImageIndex: 0,
        stripe_id: 'price_1O1xKOKb1lrs98cwbSoq34bp',
        stripe_purchase_link: 'https://buy.stripe.com/bIY28y3Re925gNyeUU'
    },
    {
        id: 'coffee',
        name: 'Buy Me a Coffee',
        description: 'Support me with a cup of coffee!',
        price: 5.0,
        images: ['coffee.png'],
        currentImageIndex: 0,
        stripe_id: 'price_1O1xPgKb1lrs98cwOhw2IA70',
        stripe_purchase_link: 'https://buy.stripe.com/bIY6oObjGcehbte4gh'
    },
    {
        id: 'session',
        name: '3hr Working Session',
        description: 'Dive deep into a topic of your choice with me.',
        price: 300.00,
        images: ['working_session.png'],
        currentImageIndex: 0,
        stripe_id: 'price_1O1xd9Kb1lrs98cw6v1eP191',
        stripe_purchase_link: 'https://buy.stripe.com/3cs28y3Re4LP2WI5km'
    }
];

export default products;