import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

const Blog = ({ blogs }) => {
  return (
    <div className="blog-list-container">
      {blogs.map(blog => (
        <div key={blog.id} className="blog-list-item">
          <h2>{blog.title}</h2>
          <p>{blog.excerpt}</p>
          <Link to={`/blog/${blog.id}`}>Read more</Link>
        </div>
      ))}
    </div>
  );
};

export default Blog;