import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

function Contact() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [serverMessage, setServerMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = e.target.elements.name?.value;
    const email = e.target.elements.email?.value;
    const message = e.target.elements.message?.value;

    const formData = {
      name,
      email,
      message
    };

    axios.post('https://kelly-enterprises-website-server.azurewebsites.net/contact_form/', formData)
        .then(res => {
          console.log(res.data);
          setIsSubmitted(true);
          setServerMessage('Message Sent!');
        })
        .catch(err => {
          console.log(err);
          setServerMessage('Error sending message!');
        })
  }

  return (
    <div className="container">
      <h2>Contact Us</h2>
      {isSubmitted ? (
        <div className="alert alert-success mt-5" role="alert">
          {serverMessage}
        </div>
      ) : (
        <form id="contact-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" className="form-control" id="name" name="name" required />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" className="form-control" id="email" name="email" required />
          </div>

          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea className="form-control" id="message" name="message" required></textarea>
          </div>

          <button type="submit" className="btn btn-primary">Submit</button>
        </form>
      )}
    </div>
  )
}

export default Contact;