import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import './index.css';
import products from './products'

const Store = () => {
    const [productsState, setProductsState] = useState(products);

    // How to navigate through the images being displayed
    const handleNextImage = (productId, direction) => {
        setProductsState(prevState => {
            return prevState.map(product => {
                if (product.id === productId) {
                    let nextIndex = product.currentImageIndex;
                    if (direction === 'next') {
                        nextIndex = (product.currentImageIndex + 1) % product.images.length;
                    } else if (direction === 'prev') {
                        nextIndex = (product.currentImageIndex - 1 + product.images.length) % product.images.length;
                    }
                    return {...product, currentImageIndex: nextIndex};
                }
                return product;
            });
        });
    };

    return (
        <div className="merch-store">
            <h1>Merch Store</h1>
            <div className="products">
                {productsState.map(product => (
                    <Product key={product.id} product={product} handleNextImage={handleNextImage} />
                ))}
            </div>
        </div>
    );
}


const Product = ({ product, handleNextImage }) => {
    return (
        <div className="product">
            <div className="carousel">
                <img src={process.env.PUBLIC_URL + product.images[product.currentImageIndex]} alt={product.name} />
                {/* <img src={'https://www.kellyenterprises.xyz/' + product.images[product.currentImageIndex]} alt={product.name} />  */}
                <div className="carousel-controls">
                    <button className="prev" onClick={() => handleNextImage(product.id, 'prev')}>❮</button>
                    <button className="next" onClick={() => handleNextImage(product.id, 'next')}>❯</button>
                </div>
                <div className="carousel-indicators">
                    {product.images.map((_, index) => (
                        <span key={index} className={index === product.currentImageIndex ? 'active' : ''}></span>
                    ))}
                </div>
            </div>
            <h3>{product.name}</h3>
            <p>{product.description}</p>
            <p>${product.price.toFixed(2)}</p>
            <Link to={product.stripe_purchase_link} target="_blank" >
                <button type="button" className="purchase-button">Buy Now</button>
            </Link>
        </div>
    );
}

export default Store;
