import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

function Home() {
    return (
        <div>
            <section className="hero">
                <h1>Welcome to Kelly Enterprises LLC</h1>
                <h2>Your trusted partner for technology consulting and scientific research</h2>
                <Link to="/contact">
                    <button>Contact Us</button>
                </Link>
            </section>
            <section className="about">
                <h2>About Us</h2>
                <p>We are experts in machine learning, generative AI, neurotechnology, mathematics, and knowledge discovery.</p>
            </section>
            <section className="services">
                <h2>Services</h2>
                <p>Offering top-notch consulting services in machine learning, generative AI, neurotechnology, and more.</p>
            </section>
            <section className="contact-teaser">
                <h2>Ready to discuss your project?</h2>
                <Link to="/contact">
                    <button>Contact Us</button>
                </Link>
            </section>
        </div>
    );
}

export default Home;