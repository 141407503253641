import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import './index.css';

const BlogDetail = ({ blogs }) => {
  const { id } = useParams();
  const blog = blogs.find(blog => blog.id === Number(id));
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(blog.content)
      .then(response => response.text())
      .then(text => setMarkdown(text));
  }, [blog]);

  return (
    <div className="blog-detail-container">
      {markdown ? (
        <>
          <h1>{blog.title}</h1>
          <Markdown>{markdown}</Markdown>
        </>
      ) : (
        <p className="blog-not-found">Blog not found</p>
      )}
    </div>
  );
};

export default BlogDetail;