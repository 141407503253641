import React from 'react';
import './index.css';

function Footer() {
    return (
        <footer>
            <div className="footer-text">
                © {new Date().getFullYear()} Kelly Enterprises. All rights reserved.
                <ul className="footer-links">
                    <li>
                        <a href="https://twitter.com/skelly240" target="_blank" rel="noopener noreferrer">Twitter</a>
                    </li>
                    <li>
                        <a href="https://github.com/spkell" target="_blank" rel="noopener noreferrer">Github</a>
                    </li>
                    <li>
                        <p>sean@kellyenterprises.xyz</p>
                    </li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;