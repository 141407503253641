import React from 'react';
import './index.css';

const Services = () => {
    return (
        <div className="services-page">
            <header className="services-header">
                <h1>Our Specialized Services</h1>
                <p>At Kelly Enterprises, we pride ourselves on our deep expertise in a wide range of technical areas. Discover more about our specialty services below:</p>
            </header>

            <section className="service-list">
                {services.map((service, index) => (
                    <div key={index} className="service-item">
                        <h3>{service.title}</h3>
                        <p>{service.description}</p>
                    </div>
                ))}
            </section>
        </div>
    );
}

const services = [
    { title: "Artificial Intelligence", description: "Harness the power of AI to transform your business processes and drive innovation." },
    { title: "Machine Learning", description: "Unlock data-driven insights and improve decision-making with our advanced ML solutions." },
    { title: "Neuroscience", description: "Dive deep into the complexities of the human brain and its functionalities." },
    { title: "Neurotechnology", description: "Integrate cutting-edge neurotech into your products and solutions." },
    { title: "Quantum Computing", description: "Step into the future with our quantum computing expertise and solutions." },
    { title: "Sales Forecasting and Demand Planning", description: "Optimize your sales strategy with accurate forecasts and planning." },
    { title: "Generative AI", description: "Create content, designs, and more with the power of generative AI models." },
    { title: "Natural Language Processing", description: "Make your systems understand and respond to human language effortlessly." },
    { title: "Cloud Architecture", description: "Build robust and scalable cloud infrastructures tailored to your business needs." },
    { title: "Intelligent Agents", description: "Deploy smart agents that act and learn autonomously to optimize processes." },
    { title: "Knowledge Engineering", description: "Structure and manage knowledge to empower AI systems and applications." },
    { title: "Mathematics", description: "Innovate your research and development approaches leveraging advanced computational methodologies." },
];

export default Services;
