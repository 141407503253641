import {React} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../components/Home';
import About from '../components/About';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import Blog from '../components/Blog';
import BlogDetail from '../components/BlogDetail';
import blogs from '../blogs/data';

import Store from '../components/Store';

function App() {
return (
    <Router>
        <div className="App">
            <Navigation/>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/blog" element={<Blog blogs={blogs} />} />
                <Route path="/blog/:id" element={<BlogDetail blogs={blogs} />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/store" element={<Store />} />
            </Routes>
        </div>
        <Footer />
    </Router>
    );
}

export default App;