import React from 'react';
import { Link } from 'react-router-dom';
import './index.css';

function Navigation() {
    return (
        <header>
            <nav className="navbar">
                <div className="container">
                    <h1 className="logo">
                        <img src={'/logo/logo192.png'} alt="Logo" className="logo-img" />  
                        <Link to="/">Kelly Enterprises</Link>
                    </h1>
                    <ul>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/services">Services</Link>
                        </li>
                        <li>
                            <Link to="/blog">Blog</Link>
                        </li>
                        <li>
                            <Link to="/store">Store</Link>
                        </li>
                        <li>
                            <Link to="/contact">Contact</Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Navigation;