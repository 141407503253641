import React from 'react';
import './index.css';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="About">
      <h1>About Us!</h1>
      <section>
        <h2>About Kelly Enterprises</h2>
        <p>Kelly Enterprises is a consultancy with a novel approach, offering advanced techniques from across a range of scientific domains. Our mission is to push the limits of human capabilities through the integration of knowledge and to expand knowledge by leveraging and exploring computational patterns. We envision a future where cutting-edge techniques can be transferred across domains, enabling humanity to build modular, efficient solutions. Through our dedication to design thinking and the scientific method, we aim to approach problems in new ways and iterate quickly to find the best solutions.</p>
      </section>
      
      <section>
        <h2>Meet the Team</h2>
        <div>
          <h3>Sean Kelly</h3>
          <p>Founder and CEO</p>
          <p>From neuroscience and explainable AI to business administration, computer networking, and creating automated AI pipelines for customer intelligence, Sean's versatility and leadership pave the way for Kelly Enterprises.</p>
       </div>
      </section>
      
      <section>
        <h2>Our Story</h2>
        <p>We originated from a diverse background, including business administration, computer networking, and customer intelligence. Our journey has been filled with significant milestones like applying machine learning and automated patterns to multiple seemingly unrelated problems, thereby adding immense value after instantiating standard data practices for our clients.</p>
      </section>

      <section>
        <h2>What Our Customers Are Saying</h2>
        <p>We are working on getting testimonials from our clients. Check back soon!</p>
      </section>

       <section>
        <h2>Let's move forward together</h2>
        <p>If you have any machine learning opportunities for implementation, involving automation of financial insights, life sciences research, or knowledge management, we'd love to hear from you. Let's explore how we can collaborate and create value together.</p>
        <Link to="/services">
          <button>Our Services</button>
        </Link>
      </section>
      
    </div>
  );
};

export default About;